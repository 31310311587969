import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/Layout"
import styles from '../styles/home.module.css'
import { Amplify } from 'aws-amplify';
import { useAuthenticator } from "@aws-amplify/ui-react";



export function Home() {
  const { signOut, user } = useAuthenticator();
  return (
    <Layout>
      <section className={styles.header}>
        <div>
          <p>Caroline Frémont - Cours de Yoga pour all tous</p>
          now implemeting signon 

          <h1>Hello {user.username}</h1>
          <button onClick={signOut}>Sign out</button>
          {/* <p>UX designer & web developer based in Manchester.</p> */}
          {/* <Link className={styles.btn} to="/projects">My Portfolio Projects</Link> */}
        </div>
        {/* <img src="/banner.png" alt="site banner" style={{ maxWidth: '100%' }} /> */}
      </section>
    </Layout>
  )
}
export default function Dummy() {
  return (
    <div>
      toto
    </div>
  );
}
