import React from 'react'
import { Alert, useAuthenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";

import { Home } from "./Home";
import { Login } from "./Login";
// import "@aws-amplify/ui-react/styles.css";
import "../styles/styles.css";
import "../styles/amplify-styles.css";


import awsExports from "../aws-exports";
Amplify.configure(awsExports);

export function App() {
  const { user } = useAuthenticator();
  console.log(user);
  if (user) {
    return <Home />;
  }

  return <Login />;
}
export default function Dummy() {
  return (
    <div>
      toto
    </div>
  );
}